<script>
	import Header from '$lib/components/Header.svelte';
	import Footer from '$lib/components/Footer.svelte';
	import GdprBanner from '$lib/components/GdprBanner.svelte';
</script>

<Header />
<main>
	<slot />
</main>
<Footer />
<GdprBanner />
